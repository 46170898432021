import { differenceInHours, distanceInWordsToNow, format, isValid, parse } from 'date-fns';

export function currency(value: number | string, currency: string = '$', decimals: number = 2): string {
	value = parseFloat(String(value));
	decimals = decimals !== 0 && decimals ? decimals : 2;

	if (!isFinite(value) || (!value && value !== 0)) {
		return '';
	}

	currency = currency != null ? currency : '$';

	const stringified = Math.abs(value).toFixed(decimals);
	const int = stringified.slice(0, -1 - decimals);
	const i = int.length % 3;
	const head = i > 0 ? int.slice(0, i) + (int.length > 3 ? ',' : '') : '';
	const float = stringified.slice(-1 - decimals);
	const sign = value < 0 ? '-' : '';

	return (
		sign +
		currency +
		head +
		int.slice(i).replace('[0-9]', '$1,') +
		float
	);
}

export function dateFormat(value: string, outputFormat: string): string {
	if (!outputFormat) {
		outputFormat = 'MMM D, YYYY h:mm a';
	}

	const date = parse(value);

	if (outputFormat === 'timeago' || outputFormat === 'human') {
		if (Math.abs(differenceInHours(date, new Date())) < 24) {
			return isValid(date) ? `${distanceInWordsToNow(date)} ago` : value;
		} else {
			outputFormat = 'MMM D, YYYY h:mm a';
			return isValid(date) ? `on ${format(date, outputFormat)}` : value;
		}
	}

	return isValid(date) ? format(date, outputFormat) : value;
}

export function phoneFormat(value: string | number): string {
	if (!value) {
		return '';
	}

	value = trimPhone(String(value));
	return String(value).replace(
		/(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/,
		replacer,
	);
}

function replacer(phone: string, p1: string, p2: string, p3: string) {
	if (!p1 || !p2 || !p3) {
		return phone;
	}

	return `(${p1}) ${p2}-${p3}`;
}

function trimPhone(value: string) {
	return value.replace('+1 ', '').replace('+1', '');
}
