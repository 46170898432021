import { ClaimCheckTerms, Customer, Model, Receipt } from '@/models';

export class ClaimCheck extends Model {
	public accessed_at: number = 0;
	public allow_email_receipt: boolean = false;
	public allow_promo: boolean = false;
	public allow_text_receipt: boolean = false;
	public allow_rewards: boolean = false;
	public claim_check_terms: ClaimCheckTerms = new ClaimCheckTerms();
	public close_date: Date | null | string = null;
	public code: string | null = null;
	public collect_handicap: boolean = false;
	public collect_num_guests: boolean = false;
	public confirmation_message: null | string = null;
	public customer: Customer = new Customer();
	public email: null | string = null;
	public has_tip: boolean = false;
	public has_previous_payments: boolean = false;
	public image_url: null | string = null;
	public image_width: number | null = null;
	public location: number = 0;
	public lot_address: null | string = null;
	public lot_country: null | string = null;
	public lot_name: null | string = null;
	public lot_phone: null | string = null;
	public name: null | string = null;
	public no_validation: boolean = false;
	public open_date: Date | null | string = null;
	public paid: boolean = false;
	public pay_by_phone: boolean = false;
	public payment_amount: number | null = null;
	public payment_date: Date | null | string = null;
	public rate_type: string = 'Unspecified';
	public receipt: Receipt = new Receipt();
	public recaptcha_site_key: string | null = null;
	public request_before_payment: boolean = false;
	public request_car: boolean = false;
	public request_shuttle: boolean = false;
	public requested: null | number = null;
	public requested_time: Date | null | string = null;
	public reservation: null | string = null;
	public rewards_button_text: null | string = null;
	public rewards_description: null | string = null;
	public rewards_display_text: null | string = null;
	public rewards_required: boolean = false;
	public show_receipt_button: boolean = false;
	public terminal_selected: null | string = null;
	public sub_location: number = 1;
	public terminals: any[] = [];
	public text_number: null | string = null;
	public ticket: null | string = null;
	public tip_settings: any[] | null = null;
	public remove_hosted_cc_forms: boolean = false;

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);
	}
}
