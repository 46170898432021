export abstract class Model {
	public constructor(data: any = {}) {
		this.initialize(data);
	}

	public initialize(data: any = {}) {
		Object.keys(data).forEach((key: string) => {
			if (this.hasOwnProperty(key)) {
				(this as any)[key] = data[key];
			}
		});
	}
}
