import axios, { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';
import _set from 'lodash/set';
import Vue from 'vue';
import { ActionContext } from 'vuex';

/**
 * Mutation Helpers
 */

/**
 * Push an item onto an array.
 *
 * @param key
 */
export const pushTo = (key: string) => (state: any, val: any) => {
	const array = get(state, key, []);
	array.push(val);
};

export const set = (key: string) => (state: any, value: any) => {
	_set(state, key, value);
};

export const setModel = (key: string, Model: any) => (
	state: any,
	value: any,
) => {
	_set(state, key, new Model(value));
};

export const setModelArray = (key: string, Model: any) => (
	state: any,
	value: any,
) => {
	Vue.set(
		state,
		key,
		value.map((item: any) => {
			return new Model(item);
		}),
	);
};

/**
 * Action Helpers
 */
export const fetch = (
	method: string = 'get',
	url?: string,
	commit?: string,
	action?: string,
	successMessage?: string,
) => (context: ActionContext<any, any>, payload: any) => {
	url = url || payload.url;
	delete payload.url;

	if (!url) {
		return false;
	}

	return new Promise((resolve, reject) => {
		context.dispatch('startRequest', true, { root: true });

		const options: AxiosRequestConfig = {
			method,
			url,
		};

		if (String(options.method).toLowerCase() === 'get') {
			options.params = payload;
		} else {
			options.data = payload;
		}

		axios(options)
			.then((response: any) => {
				context.dispatch(
					'successfulRequest',
					{
						response: response.data,
						message: successMessage,
					},
					{ root: true },
				);

				if (commit) {
					context.commit(commit, response.data);
				}

				if (action) {
					context.dispatch(action, response.data);
				}

				return resolve(response.data);
			})
			.catch((errors: any) => {
				context.dispatch('failedRequest', errors, { root: true });

				return reject(errors);
			});
	});
};

export const setFind = (
	comparator: string,
	searchTarget: string,
	mutation: string,
	type: string = 'state',
) => (context: ActionContext<any, any>, value: any) => {
	const search = type === 'state' ? context.state : context.getters;

	const foundValue = search[searchTarget].find(
		(item: any) => item[comparator] === value,
	);

	context.commit(mutation, foundValue);
};
