import { Model } from '@/models';

export class Location extends Model {
	public active: boolean = true;
	public address: string | null = '';
	public address2: string | null = null;
	public airport_code: string | null = null;
	public area: string | null = null;
	public capacity: number = 0;
	public city: string | null = null;
	public country: string | null = null;
	public email: string | null = null;
	public id: number = 0;
	public logo: string | null = null;
	public name: string = '';
	public phone: string | null = null;
	public region: string | null = null;
	public state: string | null = null;
	public text_number: string | null = null;
	public zip: string | number | null = null;

	public eparking_settings: any = null;

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);
	}
}
