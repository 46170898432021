import { Model } from '@/models';

export class CreditCard extends Model {
	public api_key: string = '';
	public subLocation: number = 0;
	public processor: string = '';
	public source: string = '';
	public cvv: null | number | string = null;
	public expiration: number | string = '';
	public name = '';
	public issuer_id: string = '';
	public cardshieldticket: string = '';
	public number: number | string = '';
	public position: 'primary' | 'secondary' | null = null;
	public token: number = 0;
	public type: string = '';
	public zip = '';

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);
	}
}
