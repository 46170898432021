
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

const NpButtonSetup = Vue.extend({
	name: 'NpButton',
	props: {
		vuex: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState(['loading']),
	},
});

@Component
export default class NpButton extends NpButtonSetup {
	public get isLoading(): boolean {
		return this.vuex && this.loading;
	}
}
