import has from 'lodash/fp/has';
import get from 'lodash/get';
import _set from 'lodash/set';
import { Module } from 'vuex';

import { ClaimCheck, Quote, Receipt, Location } from '@/models';
import { fetch, set, setModel } from '@/utilities/vuexHelpers';

export default {
	namespaced: true,

	state: {
		claimCheck: new ClaimCheck(),
		originalReceipt: new Receipt(),
		location: new Location(),
		request: {
			num_guests: null as null | number,
			is_handicap: false,
			terminal: null as any,
		},
		checkin_settings: null,
	},

	getters: {
		balanceDue(state): number {
			return has('receipt.balance_due', state.claimCheck)
				? state.claimCheck.receipt.balance_due
				: state.claimCheck.receipt.total;
		},

		collectGuests(state): boolean {
			return get(state, 'claimCheck.collect_num_guests', false);
		},

		collectHandicap(state): boolean {
			return get(state, 'claimCheck.collect_handicap', false);
		},

		originalBalanceDue(state): number {
			return has('balance_due', state.originalReceipt)
				? state.originalReceipt.balance_due
				: state.originalReceipt.total;
		},

		promoSavings(state, getters): number {
			return getters.originalBalanceDue - getters.balanceDue;
		},

		terminals(state): any[] {
			return get(state, 'claimCheck.terminals', []);
		},
	},

	mutations: {
		setClaimCheck: setModel('claimCheck', ClaimCheck),
		setGuests: set('request.num_guests'),
		setHandicap: set('request.is_handicap'),
		setOriginalReceipt: setModel('originalReceipt', Receipt),
		setRewardsDescription: set('request.rewards_description'),
		setTerminal: set('request.terminal'),
		updateReceipt(state, payload: Quote) {
			_set(
				state.claimCheck,
				'receipt.airport_tax',
				payload.airport_taxes_subtotal,
			);
			_set(
				state.claimCheck,
				'receipt.sales_tax',
				payload.sales_taxes_subtotal,
			);
			_set(state.claimCheck, 'receipt.savings', payload.savings);

			if (has('receipt.balance_due', state.claimCheck)) {
				_set(state.claimCheck, 'receipt.balance_due', payload.grand_total);
			} else {
				_set(state.claimCheck, 'receipt.total', payload.grand_total);
			}
		},
		setLocation: setModel('location', Location),
		setCheckinSettings(state, payload: any) {
			state.checkin_settings = payload;
		},
	},

	actions: {
		fetchClaimCheck: fetch('get', '', '', 'handleClaimCheck'),
		fetchRate: fetch('get'),
		fetchQuote: fetch('get', '', 'updateReceipt'),
		handleClaimCheck(context, payload: ClaimCheck) {
			context.commit('setClaimCheck', payload);
			context.commit('setOriginalReceipt', payload.receipt);
		},
		sendMembership: fetch('post', '', '', 'handleMembership'),
		sendReceipt: fetch('get'),
		sendRequest: fetch('post', '', '', 'handleClaimCheck'),
		sendPayment: fetch('post', '', '', 'handleClaimCheck', 'Your payment has been received.'),
	},
} as Module<any, any>;
