import get from 'lodash/get';
import merge from 'lodash/merge';
import Vue from 'vue';
import Vuex from 'vuex';

import claimCheck from '@/store/claimCheck';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		claimCheck,
	},

	state: {
		baseUrl: '' as string,
		localDev: false as boolean,
		loading: false as boolean,
		pageTime: 0 as number,
		processedPayment: false as boolean,
		successMessage: '' as string,
		token: '' as string,
		tokenData: {} as any,
	},

	getters: {
		localDev(state): boolean {
			return get(state, 'localDev');
		},

		location(state): number {
			return get(state, 'claimCheck.claimCheck.location') as unknown as number;
		},

		logo(state): { url: string; width: number } {
			return {
				url: get(state, 'claimCheck.claimCheck.image_url', ''),
				width: get(state, 'claimCheck.claimCheck.image_width', 0),
			};
		},

		subLocation(state): number {
			return +get(state.tokenData, 'sub_location', 0);
		},

		ticket(state): string {
			return get(state.tokenData, 'ticket', '');
		},

		customer(state): string {
			return get(state.tokenData, 'customer', '');
		},

		e_signature_id(state): string {
			return get(state.tokenData, 'e_signature_id', '');
		},

		type(state): string {
			return get(state.tokenData, 'type', '');
		},
	},

	mutations: {
		incrementPageTime(state: any, amount?: number) {
			amount = amount || 1;
			state.pageTime += amount;
		},
		resetPageTime(state: any) {
			state.pageTime = 0;
		},
		setLoading(state: any, isLoading: boolean) {
			state.loading = isLoading;
		},
		setProcessedPayment(state: any, value?: boolean) {
			if (value !== true && value !== false) {
				state.processedPayment = !state.processedPayment;
			} else {
				state.processedPayment = value;
			}
		},
		setSuccessMessage(state: any, message?: string) {
			state.successMessage = message || '';
		},
		setToken(state: any, token: string) {
			state.token = token;
		},
		setTokenData(state: any, tokenData: any) {
			state.tokenData = tokenData;
		},
	},

	actions: {
		failedRequest({ commit }) {
			commit('setLoading', false);
			commit('resetPageTime');
			// commit('setErrors', errors);
		},

		startRequest({ commit }) {
			commit('setLoading', true);
		},

		successfulRequest({ commit }, payload: any) {
			commit('setLoading', false);
			commit('resetPageTime');

			if (payload.message) {
				commit('setSuccessMessage', payload.message);
			}
		},
	},
});

store.replaceState(merge({}, store.state, VUEX_DATA) as any);

export default store;
