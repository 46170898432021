
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SuccessResponseAlert extends Vue {
	@Prop({ type: Boolean, default: true }) public closeButton!: boolean;
	@Prop(Number) public duration!: number;

	public get successMessage(): string {
		return this.$store.state.successMessage;
	}

	public set successMessage(value: string) {
		this.$store.commit('setSuccessMessage', value || '');
	}

	public created() {
		if (this.duration > 0) {
			this.$store.subscribe(mutation => {
				if (mutation.type === 'setSuccessMessage' && mutation.payload) {
					setTimeout(() => {
						this.successMessage = '';
					}, this.duration);
				}
			});
		}
	}
}
