import { Model } from '@/models';

export class Receipt extends Model {
	public airport_tax: number = 0;
	public balance_due: number = 0;
	public receipt: any = [];
	public total: number = 0;
	public sales_tax: number = 0;
	public savings: number = 0;

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);
	}
}
