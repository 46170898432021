
import SuccessResponseAlert from '@/components/SuccessResponseAlert.vue';
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import Fetch from '@/utilities/Fetch';
import { Location } from '@/models';

export default Vue.extend({
	name: 'App',

	components: {
		SuccessResponseAlert,
	},

	data() {
		return {
			policyHTML: '',
			printerURL: 'http://127.0.0.1:7275/eparking.js',
			token: TOKEN,
			tokenData: TOKEN_DATA,
			utilitiesAvailable: false,
			utilitiesHTML: '',
			location: {} as Location,
		};
	},

	computed: {
		...mapState(['pageTime']),
	},

	async created() {
		const results = await Fetch.get(`/api/checkin/${this.token}`);
		this.location = new Location(results);

		setInterval(this.handleActivity, 5000);

		this.checkPrinter();
	},

	methods: {
		...mapMutations(['incrementPageTime']),

		async checkPrinter() {
			const response = await fetch(this.printerURL, {
				method: 'GET',
				mode: 'cors',
			}).catch(() => {});

			if (response && response.status === 200) {
				this.utilitiesAvailable = true;
			}
		},

		async getPolicy() {
			if (this.policyHTML) {
				return true;
			}

			try {
				const response = await Fetch.get('/privacy_policy.html');
				this.policyHTML = response;
			} catch (error) {}
			return true;
		},

		async getUtilities() {
			if (this.utilitiesHTML) {
				return true;
			}

			try {
				const response = await Fetch.get('/utility_download.html');
				this.utilitiesHTML = response;
			} catch (error) {}
			return true;
		},

		handleActivity() {
			this.incrementPageTime(5);

			if (this.pageTime >= 1800) {
				window.location.reload();
			}
		},

		showPolicy() {
			this.$modal.show('PolicyModal');
		},

		showUtilities() {
			this.$modal.show('UtilitiesModal');
		},

		locationCheck() {
			if (this.location && this.location.eparking_settings) {
				return !this.location.eparking_settings.text_on_footer;
			}
			return false;
		},
	},
});
