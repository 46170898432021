
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'ScreenLoader',
	props: {
		vuex: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState({ vuexLoading: 'loading' }),
		isLoading(): boolean {
			return this.loading || (this.vuex && (this as any).vuexLoading);
		},
	},
});
