import Vue from 'vue';
import Router from 'vue-router';
import Fetch from '@/utilities/Fetch';
import store from './store';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: '/',
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue'),
			props: true,
			meta: {
				title: 'E-Parking',
			},
		},
		{
			path: '/emails/:token/preferences',
			name: 'emails',
			component: () => import(/* webpackChunkName:  "checkin" */ '@/pages/Emails.vue'),
			props: true,
			meta: {
				title: 'Your Email Preferences | E-Parking',
			},
		},
		{
			path: '/checkin',
			name: 'checkin',
			component: () => import(/* webpackChunkName:  "checkin" */ '@/pages/checkin/CheckinIndex.vue'),
			props: true,
			meta: {
				title: 'Mobile Check-in Location | E-Parking',
			},
		},
		{
			path: '/checkin/:token',
			name: 'checkin-show',
			component: () => import(/* webpackChunkName:  "checkin" */ '@/pages/checkin/CheckinShow.vue'),
			props: true,
			beforeEnter: (to, from, next) => {
				Fetch.get(`/api/checkin/${to.params.token}`)
					.then((response) => {
						store.commit('claimCheck/setLocation', response);
						store.commit('claimCheck/setCheckinSettings', JSON.parse(response.eparking_settings.checkin_settings));
						next();
					})
					.catch((error) => {
						console.log(error);
						next();
					});
			},
			meta: {
				title: 'Mobile Check-in | E-Parking',
			},
		},
		{
			path: '/checkin/search/:token',
			name: 'checkin-search',
			component: () => import(/* webpackChunkName:  "checkin" */ '@/pages/checkin/CheckinSearch.vue'),
			props: true,
			meta: {
				title: 'Mobile Check-in Search | E-Parking',
			},
		},
		{
			path: '/eSignature/:token',
			name: 'esignature',
			component: () =>
				import(/* webpackChunkName: "esignature" */ '@/pages/eSignature.vue'),
			meta: {
				title: 'eSignature',
			},
		},
		{
			path: '/ticket/:token',
			name: 'claimcheck',
			component: () =>
				import(/* webpackChunkName: "claimcheck" */ '@/pages/ClaimCheck.vue'),
			meta: {
				title: 'Claim Check | E-Parking',
			},
		},
		{
			path: '/surveys/:token',
			name: 'survey',
			component: () =>
				import(/* webpackChunkName: "survey" */ '@/pages/Survey.vue'),
			meta: {
				title: 'Survey | E-Parking',
			},
		},
		{
			path: '/surveys/:token/thank-you',
			name: 'surveythankyou',
			component: () => import(/* webpackChunkName: "surveythankyou" */ '@/pages/SurveyThankYou.vue'),
			props: true,
			meta: {
				title: 'Survey | E-Parking',
			},
		},
		{
			path: '/pay/:token',
			name: 'payment',
			component: () =>
				import(/* webpackChunkName: "paybyphone" */ '@/pages/Payment.vue'),
			meta: {
				title: 'Pay Now | E-Parking',
			},
		},
		{
			path: '/reservations/:token',
			component: () => import(/* webpackChunkName: "Reservation" */ '@/pages/Reservation.vue'),
			props: true,
			meta: {
				title: 'Viewing Reservation | E-Parking',
			},
			children: [{
				path: ':action',
				name: 'reservation-action',
				props: true,
				component: () => import(/* webpackChunkName: "Reservation" */ '@/pages/Reservation.vue'),
			}],
		},
		{
			path: '/validations',
			component: () => import(/* webpackChunkName:  "validations" */ '@/pages/Validations.vue'),
			props: true,
			meta: {
				title: 'E-Validations | E-Parking',
			},
			children: [{
				path: ':token',
				name: 'validations-show',
				props: true,
				component: () => import(/* webpackChunkName: "validations" */ '@/pages/validations/ValidationsShow.vue'),
			}, {
				path: '/',
				name: 'validations',
				props: true,
				component: () => import(/* webpackChunkName: "validations" */ '@/pages/validations/ValidationsIndex.vue'),
			}],
		},
		{
			path: '/vouchers/:token',
			component: () => import(/* webpackChunkName: "voucher" */ '@/pages/Voucher.vue'),
			props: true,
			meta: {
				title: 'E-Validation | E-Parking',
			},
		},
		{
			path: '/:token',
			name: 'default',
			redirect: { name: 'claimcheck' },
		},
		{
			path: '*',
			name: 'error',
			props: true,
			component: () => import(/* webpackChunkName:  "error" */ '@/pages/ErrorPage.vue'),
			meta: {
				title: 'Error | E-Parking',
			},
		},
	],
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

router.beforeEach((to, _, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r: any) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	/*
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r: any) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r: any) => r.meta && r.meta.metaTags);
	*/

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	}

	if (to.query && to.query.stylesheet && !document.getElementById('external-stylesheet')) {
		const link = document.createElement('link');
		link.id = 'external-stylesheet';
		link.type = 'text/css';
		link.rel = 'stylesheet';
		link.href = to.query.stylesheet as string;
		document.head.append(link);
	}

	next();
});

export default router;
