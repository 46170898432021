import store from '@/store';

class Fetch {
	public get(url: string, data?: any, options?: any) {
		return this.request(url, 'GET', data, options);
	}

	public post(url: string, data?: any) {
		return this.request(url, 'POST', data);
	}

	public delete(url: string, data?: any) {
		return this.request(url, 'DELETE', data);
	}

	public put(url: string, data?: any) {
		return this.request(url, 'PUT', data);
	}

	public request(url: string, method: string, data: any = {}, options: any = {}): Promise<any> {
		options = {
			method: method.toUpperCase(),
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				'Content-Type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Api-Token': store.state.token || '',
				'X-Sub-Location': (store.state.tokenData && store.state.tokenData.sub_location) || '',
			},
			body: ['PUT', 'POST', 'DELETE'].includes(method) ? JSON.stringify(data) : null,
			...options,
		};

		if (method === 'GET' && data) {
			const qs = Object.keys(data).reduce(function (a: string[], k) { a.push(k + '=' + encodeURIComponent(data[k])); return a }, []).join('&');
			url += '?' + qs;
		}

		return new Promise((resolve, reject) => {
			fetch(url, options)
				.then(async (response) => {
					const contentType = response.headers.get('content-type');
					if (response.status >= 400) {
						throw await response.json();
					} else {
						if (contentType && contentType.toLowerCase().includes('pdf')) {
							return response.blob();
						} else if (contentType && contentType.toLowerCase().includes('html')) {
							return response.text();
						}

						return response.json();
					}
				})
				.then((response) => {
					if (response instanceof Blob) {
						try {
							const url = (window.URL).createObjectURL(response);
							const anchor = document.createElement('a');
							anchor.setAttribute('href', url);
							anchor.setAttribute('download', 'validations.pdf');
							anchor.style.display = 'none';
							document.body.appendChild(anchor);
							anchor.click();
							setTimeout(() => {
								anchor.remove();
								window.URL.revokeObjectURL(url);
							}, 0);

							resolve('PDF');
						} catch (error) {
							// PDF error
							reject(error);
						}
					}

					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}

export default new Fetch();
