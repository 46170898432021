import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faArrowLeft,
	faCamera,
	faInfoCircle,
	faMinus,
	faPlus,
	faPlusCircle,
	faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
	faArrowLeft,
	faCamera,
	faInfoCircle,
	faMinus,
	faPlus,
	faPlusCircle,
	faTimesCircle,
);

Vue.component('FaIcon', FontAwesomeIcon);
